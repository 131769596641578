<template lang="pug">
  .singup
    .singup__top(:class="{'email_confirm': emailConfirm}")
      a.singup__top-arrow(v-if="!showCaptchaDialog && !emailConfirm", href="//kickex.com")
        SvgArrowLeft
      .singup__top-arrow(v-else, @click="goSignUp")
        SvgArrowLeft()
      .singup__top-route {{ $t('authTitles.suggestAuth') }}
        router-link(:to="{ name: 'signin' }") {{ $t('authTitles.authorize') }}
      LanguageSwitching
    .singup__form(v-if="!emailConfirm" v-show="!showCaptchaDialog")
      h1 {{ $t('authTitles.signup') }}
      .form_block
        v-form.form(ref="form", v-model="valid", @submit.prevent="")
          v-layout(column)
          .singup__email
            div {{ $t('formFields.email') }}
            v-text-field(
              ref="formEmail",
              :label="$t('formFields.email')",
              :placeholder="placeholder",
              v-model.trim="email",
              name="email",
              :rules="[rules.required, rules.email]",
              :error-messages="serverValidationErrors('emailErrors')",
              @input="emailErrors = []")
          .singup__password
            div {{ $t('formFields.password') }}
            .password
              v-text-field(
                v-model="password",
                :label="$t('formFields.password')",
                :placeholder="placeholder",
                :type="showPassword ? 'text' : 'password'",
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'",
                :error="passwordHasError"
                :error-messages="serverValidationErrors('passwordErrors')",
                autocomplete="off"
                @focus="passwordHintsVisible = true"
                @blur="passwordHintsVisible = false"
                @click:append="showPassword = !showPassword",
                @input="onPasswordInput")
              ul.password-validation(v-if="passwordHintsVisible && passwordHasError")
                li(v-for="rule in rules.password", :key="rule.message", :class="{'with-error': rule.error}")
                  SvgError(v-if="rule.error")
                  SvgCheck(v-else)
                  | {{ rule.message }}
            .errors(v-if="generalFormErrors.length")
              .errors__item(v-for="error in generalFormErrors") #[strong {{ error.title }}]: #[span(v-html="error.detail")]
          v-btn.singup__button(:disabled="!valid || loading", @click="submitForm", type="submit", data-name="landing-signup") {{ $t('actions.createAccount') }}
      h2.singup__info(v-html="$t('hints.agreeToTerms') + $t( 'hints.agreeToPrivacyPolicy')")
      SocialSignIn
    EmailConfirm(v-if="emailConfirm")
    CaptchaDialog(
      :visible="showCaptchaDialog",
      :loading="loading",
      :errors="errorsCaptcha",
      @clickClose="showCaptchaDialog = false",
      @submit="handleCaptchaSubmit")
</template>

<script>
import { mapState } from 'vuex';
import SocialSignIn from '@/components/SocialSignIn';
import SecureCodeInputs from '@/components/SecureCodeInputs';
import EmailConfirm from '@/components/EmailConfirm';
import SvgAim from '@/components/svg/SvgAim';
import SvgCheck from '@/components/svg/SvgCheckRound';
import SvgError from '@/components/svg/SvgCloseRound';
import SvgArrowLeft from '@/components/svg/SvgArrowLeft';
import CaptchaDialog from '@/components/CaptchaDialog';
import REGEXP from '@/regexp';
import CONSTANTS from '@/constants';
import LanguageSwitching from '@/components/LanguageSwitching';

export default {
  components: {
    SocialSignIn,
    SecureCodeInputs,
    SvgAim,
    SvgCheck,
    SvgError,
    CaptchaDialog,
    SvgArrowLeft,
    EmailConfirm,
    LanguageSwitching
  },
  data() {
    return {
      valid: false,
      loading: false,
      email: '',
      password: '',
      passwordAgain: '',
      placeholder: '',
      terms: false,
      policy: true,
      errors: null,
      emailErrors: [],
      passwordErrors: [],
      policyErrors: [],
      generalFormErrors: [],
      showPassword: false,
      showPasswordConfirm: false,
      showCaptchaDialog: false,
      errorsCaptcha: [],
      formData: null,
      passwordHintsVisible: false,
      passwordHasError: false,
      emailConfirm: false
    };
  },
  mounted() {
    this.autofillFix();
    this.$store.dispatch('user/setImgName', 'signup');
  },
  methods: {
    goSignUp() {
      this.showCaptchaDialog = false;
      this.$store.dispatch('user/setImgName', 'signup');
    },
    autofillFix() {
      if (navigator.userAgent.indexOf('AppleWebKit') >= -1 && navigator.userAgent.toLowerCase().indexOf('firefox') === -1) {
        try {
          document.querySelector('input[name="email"]:-webkit-autofill');

          let count = 0;
          const interval = setInterval(() => {
            count++;

            if (document.querySelector('input[name="email"]:-webkit-autofill')
            && document.querySelector('input[type="password"]:-webkit-autofill')) {
              this.placeholder = ' ';
              this.valid = true;
              clearInterval(interval);
            }

            if (count >= 20) clearInterval(interval);
          }, 100);
        } catch (error) {
          console.error(error);
        }
      }
    },
    serverValidationErrors(errorPropName) {
      if (this[errorPropName].length) {
        let errMsgs = '';

        this[errorPropName].forEach(err => {
          errMsgs += `${err.title}: ${err.detail}`;
        });

        return errMsgs;
      }

      return '';
    },
    focusOnFirstInputWithExternalError() {
      const input = this.$refs.form.inputs.find(i => i.externalError);

      if (input) input.focus();
    },
    checkAndSetErrorWithSourceParam(error) {
      if (error.source.parameter.includes('email')) this.emailErrors.push(error);

      if (error.source.parameter.includes('password')) this.passwordErrors.push(error);

      if (error.source.parameter.includes('accepted_terms')) this.policyErrors.push(error);
    },
    checkAndSetErrorWithCode(error) {
      switch (error.code) {
        case '1': this.emailErrors.push(error);
          break;
        case '2': this.passwordErrors.push(error);
          break;
        case '14': this.emailErrors.push(error);
          break;
        case '48': this.generalFormErrors.push(error);
          break;
        default: this.generalFormErrors.push(error);
      }
    },
    submitForm() {
      if (!this.$refs.form.validate() || this.loading) return;

      // dispatch Google Tag Manager event
      window.dataLayer = window.dataLayer || [];

      // eslint-disable-next-line
      dataLayer.push({
        event: 'buttonClicks',
        pageName: 'id.Signup page',
        clickText: `${this.$t('actions.proceed', 'en')}-1`
      });

      this.$store.commit('user/setRegistrationType', 'email');
      this.formData = {
        email: this.email,
        password: this.password,
        accepted_terms: this.policy,
        referral_hash: localStorage.getItem('referral_hash'),
        language_id: this.languageId
      };

      this.showCaptchaDialog = true;
      this.$store.dispatch('user/setImgName', 'captcha');
    },
    signup() {
      if (this.loading) return;

      this.loading = true;
      this.formData.language_id = this.languageId;

      const payload = {
        data: {
          type: 'users',
          attributes: this.formData
        }
      };

      if (localStorage.getItem('service_id')) {
        payload.data.relationships = {
          service: {
            data: {
              type: 'services',
              id: Math.abs(Number(localStorage.getItem('service_id')))
            }
          }
        };
      }

      if (!payload.data.relationships) payload.data.relationships = {};

      payload.data.relationships['registration-type'] = {
        data: {
          id: this.$store.state.user.registrationType,
          type: 'registration-types'
        }
      };

      payload.data.relationships.language = {
        data: {
          id: this.languageId,
          type: 'languages'
        }
      };

      this.$store.dispatch('user/signup', payload)
        .then(() => this.redirectAfterSignUp())
        .catch(async errors => {
          if (errors.message) {
            this.errorsCaptcha = [{ title: this.$t('messages.error.undefined'), detail: errors.message }];
            return;
          }

          const errorsCaptcha = errors.filter(error => error.title.includes('captcha') || error.detail.includes('captcha'));

          if (errorsCaptcha.length) {
            this.errorsCaptcha = errorsCaptcha;
          } else {
            this.errors = errors;
            this.errorsCaptcha = [];
            this.showCaptchaDialog = false;
            this.$store.dispatch('user/setImgName', 'signup');
          }
        })
        .finally(() => {
          this.valid = true;
          this.loading = false;
        });
    },
    handleCaptchaSubmit(captcha) {
      this.formData.captcha_id = this.captchaId;
      this.formData.captcha_code = captcha;

      if (process.env.VUE_APP_GTM_ID && window.ga && window.ga.create) {
        this.fireGtmEventForSignUp();
      } else {
        this.signup();
      }
    },
    fireGtmEventForSignUp() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'buttonClicks',
        pageName: 'id.Sign up page',
        clickText: `${this.$t('actions.proceed', 'en')}-2`,
        eventCallback: this.signup,
        eventTimeout: 1500
      });
    },
    redirectAfterSignUp() {
      this.showCaptchaDialog = false;
      this.emailConfirm = true;
      this.$store.dispatch('user/setImgName', 'emailConfirm');
    },
    onPasswordInput(val) {
      this.passwordErrors = [];

      Object.keys(this.rules.password).forEach(key => {
        this.rules.password[key].error = !this.rules.password[key].validate(val);
      });

      this.passwordHasError = Object.keys(this.rules.password).some(key => this.rules.password[key].error);
    }
  },
  computed: {
    ...mapState('user', [
      'captchaId'
    ]),
    languageId() {
      const language = CONSTANTS.LANGUAGES.find(i => i.val === this.$i18n.locale);

      if (!language) return 1;

      return language.id;
    },
    rules() {
      return {
        name: v => REGEXP.name.test(v) || this.$t('validation.nameRules', { min: 1, max: 160 }),
        email: v => REGEXP.email.test(v.trim()) || this.$t('validation.emailInvalid'),
        passwordConfirm: v => v === this.password || this.$t('validation.passwordsDoNotMatch'),
        required: v => !!v || this.$t('validation.required'),
        min: v => v.length >= 2 || this.$t('validation.minLength', { chars: 2 }),
        max: v => v.length <= 160 || this.$t('validation.maxLength', { chars: 160 }),
        password: {
          length: {
            validate: val => REGEXP.passwordLength.test(val),
            message: this.$t('validation.passwordLengthNew', { min: 8, max: 160 }),
            error: true
          },
          complexity: {
            validate: val => REGEXP.passwordComplexity.test(val),
            message: this.$t('validation.passwordComplexity'),
            error: true
          },
          notContainEmail: {
            validate: val => {
              if (!this.email) return true;

              const emailArr = this.email.split('@');

              if (emailArr.length) {
                return !val.includes(emailArr[0]);
              }

              return true;
            },
            message: this.$t('validation.passwordNotContainEmail'),
            error: false
          }
        }
      };
    }
  },
  watch: {
    errors: {
      handler(val) {
        if (val.length) {
          val.forEach(error => {
            if (error.source && error.source.parameter) {
              // handle errors formed in line with common JSON:API specs
              this.checkAndSetErrorWithSourceParam(error);
            } else if (error.code) {
              // handle errors passed directly from KICK ID service
              this.checkAndSetErrorWithCode(error);
            } else {
              // display network and other errors not associated with user input
              this.generalFormErrors.push(error);
            }
          });

          setTimeout(this.focusOnFirstInputWithExternalError, 0);
        } else {
          this.emailErrors = [];
          this.passwordErrors = [];
          this.policyErrors = [];
          this.generalFormErrors = [];
        }
      },
      deep: true
    },
    submitBtnActive(val) {
      this.loading = !val;
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .form {
    &_double {
      & > div { max-width: 48%; }

      @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        & > div { max-width: 100%; }
      }
    }

    ::v-deep .v-input__slot { font-weight: 600; }
  }

  .errors {
    margin-bottom: 10px;
  }

  ::v-deep .v-input--selection-controls__input::before { border: 1px solid $sub-black !important; }

  .password {
    position: relative;

    &-validation {
      z-index: 3;
      position: absolute;
      top: 55px;
      left: 0;
      box-shadow: 0 10px 30px $light-theme-08;
      border-radius: 5px;
      background-color: $white;
      list-style: none;
      padding: 13px;
      width: 100%;
      text-align: left;

      li {
        display: flex;
        color: $green1;
        font-size: 12px;
        margin-bottom: 12px;

        &.with-error {
          color: $error-color;
        }

        svg {
          margin-right: 12px;
        }
      }
    }
  }
  .singup{
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 1240px) {
      /*padding-right: 17px;*/
    }
    @media screen and (max-width: 530px) {
      max-width: 100%;
    }
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 530px) {
        border-bottom: 1px solid rgba(31, 33, 38, 0.1);
        padding: 0 20px 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &-arrow{
        display: none;
        @media screen and (max-width: 530px) {
          display: block;
        }
      }
      &-route{
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        text-align: right;
        color: #000000;
        a{
          margin-left: 5px;
          text-decoration: none;
        }
      }
    }
    h1 {
      font-size: 36px;
      color: $black2;
      margin: 12vh 0 2vh;
      @media screen and (max-width: 530px) {
        margin: 20px 0;
        text-align: left;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
      }
    }

    &__email,&__password{
      margin-bottom: 20px;
      >div{
        margin-bottom: 5px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: rgba(31, 33, 38, 0.6);
        text-align: left;
      }
    }
    &__email{
      margin-bottom: 10px;
    }
    &__password{
      ::v-deep .v-text-field__details{
        display: none;
      }
    }
    &__button {
      background: #0071E3!important;
      border-radius: 5px;
      width: 100%;
      height: 40px !important;
    }
    &__info{
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #1F2126;
      margin-top: 20px;
    }
    &__form{
      @media screen and (max-width: 530px) {
        padding: 0 20px;
      }
    }
  }
  ::v-deep .v-text-field .v-label{
    opacity: 0;
  }
  ::v-deep .v-text-field .v-input__slot{
    height: 45px;
    padding-top: 5px;
  }
  ::v-deep .password .v-input__append-inner {
    margin-top: 6px !important;
  }
  ::v-deep .v-text-field input {
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    color: #1F2126;
  }
  .email_confirm{
    justify-content: flex-end;
    svg,.singup__top-route{
      display: none;
    }
  }
</style>
