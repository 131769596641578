<template>
<svg :width="size" :height="size" :style="{'min-width': `${size}px`}" viewBox="0 0 20 20" fill="none">
  <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" :stroke="color"/>
  <path d="M11.6668 8.33334L8.3335 11.6667M8.3335 8.33334L11.6668 11.6667L8.3335 8.33334Z" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: '#F83E4B'
    }
  }
};
</script>
