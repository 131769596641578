<template>
  <svg :width="size" :height="size" :style="{'min-width': `${size}px`}" viewBox="0 0 20 20" fill="none">
    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.5 10L9.16667 11.6667L12.5 8.33334" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: '#0cc466'
    }
  }
};
</script>
